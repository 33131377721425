import { createPinia } from 'pinia'
import { createApp } from 'vue';
import { Popover } from 'bootstrap';
import App from './App.vue';
import router from './router';
import './assets/style/main.scss';
import { authMiddleware } from '@/middleware/middleware';
authMiddleware();
const pinia = createPinia()

createApp(App).use(router).use(pinia)
  .mount('#app');

document.querySelectorAll('[data-bs-toggle="popover"]')
  .forEach((popover) => {
    /* eslint-disable no-new */
    new Popover(popover);
  });
