<template>
  <div class="m-select form-floating">
    <select class="form-select" 
      v-model="activeSelect"
      id="m-select" 
      aria-label="Floating label select example"
      @change="handleChange()"
    >
      <option selected>Нажмите для выбора</option>
      <template v-for="(item, i) in items" :key="i">
        <option :value="item">
          {{ item.title }}
        </option>
      </template>
    </select>
    <label for="m-select">Выберите наименование карты</label>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";

const emit = defineEmits(['activeSelect'])

const props =  defineProps({
  items: {
    type: Array,
  },
  value: {
    type: String,
    default: ()=>{
      return
    },
  }
});

watchEffect(()=>{
  console.log(props.value);
  props.items.forEach((item,index)=>{
    if (item.title == props.value) {
      activeSelect.value = item
      handleChange()
    }
  })
})

const activeSelect = ref('Нажмите для выбора');
function handleChange(){
  emit('activeSelect', activeSelect.value);
}
</script>
