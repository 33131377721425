<template>
  <SHeader />
  <main class="container mt-10 mb-10">
    <SForm />
  </main>
  <SFooter />
</template>

<script>
import SForm from "@/components/s-form.vue";
import SHeader from "@/components/s-header.vue";
import SFooter from "@/components/s-footer.vue";


// import MInputText from '@/components/m-form.vue';

export default {
  components: {
    SForm,SHeader,SFooter
  },
};
</script>
