<template>
  <header data-bs-theme="s-header dark">
    <div class="s-header__wrapper text-bg-dark collapse" id="navbarHeader">
      <div class="s-header__container container">
        <div class="s-header__items row">
          <div class="s-header__list col-sm-12 offset-md-1 py-4">
            <ul class="list-unstyled">
              <!-- <template v-for="item in routerLink" :key="item">
                <router-link :to="item.link"> 
                  {{ item.name }}
                </router-link>
              </template> -->
              <router-link to="/">Главная</router-link>
              <router-link to="/create" class="text-white"
                >Техническое задание</router-link
              >
              <router-link to="/preview" class="text-white"
                >Превью
              </router-link>
              <router-link to="/help">Помощь</router-link>
              <router-link to="/history">История</router-link>
              <router-link to="/admin">Админ. панель</router-link>
              <MLogout v-if="Cookies.get('access_token') != undefined"/>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="s-header__list navbar navbar-dark bg-dark shadow-sm">
      <div class="s-header container">
        <a href="/" class="s-header navbar-brand d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            aria-hidden="true"
            class="me-2"
            viewBox="0 0 24 24"
          >
            <path
              d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"
            ></path>
            <circle cx="12" cy="13" r="4"></circle>
          </svg>
          <strong>Все шаблоны</strong>
        </a>
        <div v-if="sectionData" class="s-header__list s-header__search">
          <form class="s-header__form d-flex" role="search">
            <input
              class="s-header__form-input form-control me-2"
              type="search"
              placeholder="Поиск"
              aria-label="Поиск"
              v-model="search"
              @input="searchData()"
            />
            <button
              class="s-header__form-btn btn btn-outline-success"
              type="submit"
              @click.prevent
            >
              Поиск
            </button>
          </form>
        </div>
        <button
          class="s-header__list s-header__btn navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarHeader"
          aria-controls="navbarHeader"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import MLogout from "@/components/m-logout.vue";
import Cookies from 'js-cookie';

const props = defineProps({
  mainData: {
    type: Array,
  },
});
const emit = defineEmits(["emitSearch"]);

const search = ref("");
const sectionData = ref(props.mainData);
watch(
  () => props.mainData,
  (newVal) => {
    sectionData.value = newVal;
  }
);
function searchData() {
  const filteredData = sectionData.value.filter((item) => {
    const lowerCaseName = item.name.toLowerCase();
    return lowerCaseName.includes(search.value);
  });
  emit("emitSearch", filteredData);
}
</script>


<style lang="scss" scoped>
.s-header {
  &__search {
    flex: 1;
    margin: 0 10px;
  }
}
</style>