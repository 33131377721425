<template>
  <SHeader />
  <main class="container m-auto">
    <h1>404</h1>
    <h2>Данной страницы не существует</h2>
  </main>
</template>

<script>
import SHeader from "@/components/s-header.vue";
import SFooter from "@/components/s-footer.vue";

// import MInputText from '@/components/m-form.vue';

export default {
  components: {
    SHeader,SFooter
  },
};
</script>
