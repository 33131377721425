<template>
  <router-view />
</template>

<script setup>
import Cookies from 'js-cookie';
import { onMounted } from "vue";
import router from './router';

onMounted(()=>{
  // // console.log(Cookies.get('access_token'));
  if (Cookies.get('access_token') == undefined) {
    router.push('/login')
  }
})
</script>
