<template>
  <div class="s-form">
    <h2 class="s-form__title">
      Бланк для составления формы технического задания
    </h2>
    <div class="s-form__container">
      <div class="m-input-text s-form__name-text">
        <div class="m-input-text__container input-group mb-3">
          <span class="input-group-text">Номер технического задания</span>
          <input required v-model="input.nameLayout" type="text" class="form-control" placeholder="Введите номер" />
        </div>
      </div>
      <form @submit.prevent="submitForm()" class="s-form__form">
        <div class="s-form__content">
          <div class="s-form__content-list s-form__content-sketch">
            <h4 class="s-form__subtitle">Эскиз для карты</h4>
            <template v-for="i in countSketchs" :key="i">
              <h5 class="s-form__content-prefix">Эскиз №{{ i }}</h5>
              <div class="s-form__sketch-items">
                <div class="s-form__sketch-item">
                  <m-drop class="s-form__drop" :index="i" :image="input.sketch[i - 1].face" @emitImage="handlerFaceImage"
                    @removeImage="removeImage" />
                  <span> Лицевая сторона </span>
                </div>
                <div class="s-form__sketch-item">
                  <M-Drop class="s-form__drop" :index="i" :image="input.sketch[i - 1].back" @emitImage="handlerBackImage"
                    @removeImage="removeImage" />
                  <span> Обратная сторона </span>
                </div>
              </div>
              <div class="m-input-text s-form__name-text">
                <div class="m-input-text__container input-group mb-3">
                  <span class="input-group-text">Кол-во</span>
                  <input required v-model="input.sketch[i - 1].quant" type="text" class="form-control"
                    placeholder="Введите количество карт" />
                </div>
              </div>
              <div class="m-input-text s-form__name-text">
                <div class="m-input-text__container input-group mb-3">
                  <span class="input-group-text">Цена в за ед.</span>
                  <input required v-model="input.sketch[i - 1].amount" type="text" class="form-control"
                    placeholder="Введите цену в за ед." aria-label="" />
                </div>
              </div>
              <div class="m-input-text s-form__name-text">
                <div class="m-input-text__container input-group mb-3">
                  <span class="input-group-text">Общая стоимость</span>
                  <input required v-model="input.sketch[i - 1].amounts" type="text" class="form-control"
                    placeholder="Введите общую стоимость" aria-label="" />
                </div>
              </div>
            </template>
            <p class="s-form__content-prefix">
              Цвета и шрифты в соответствии с предоставленным макетом формата
              «.AI».
            </p>
            <div class="s-form__sketch-btn">
              <button @click="sketchsAdd" type="button" class="s-form__sketch-add btn btn-primary">
                Добавить эскиз
              </button>
              <button v-if="arrSketch.length != 1" @click="sketchsRemove" type="button"
                class="s-form__sketch-rem btn btn-danger">
                Убрать эскиз
              </button>
            </div>
          </div>
          <div class="accordion" id="accordionPanelsStayOpenExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne">
                  Технические требования на изготовление пластиковых карт
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div class="s-form__content-list s-form__content-name">
                    <m-select :items="arrCard" :value="input.name" @activeSelect="handlerSelect" class="s-form__name-select" />
                    <div class="m-input-text s-form__name-text">
                      <div class="m-input-text__container input-group mb-3">
                        <span class="input-group-text">Наименование карты</span>
                        <input required v-model="input.name" type="text" class="form-control"
                          placeholder="Введите наименование карты" />
                      </div>
                      <div class="m-input-text__container input-group mb-3">
                        <span class="input-group-text">Серия карты</span>
                        <input required v-model="input.seria" type="text" class="form-control"
                          placeholder="Введите наименование карты" />
                      </div>
                    </div>
                    <div class="accordion">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseInnerOne" aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseOne">
                            Бесконтактный чип
                          </button>
                        </h2>
                        <div id="panelsStayOpen-collapseInnerOne" class="accordion-collapse collapse">
                          <div class="accordion-body">
                            <!-- <h5 class="s-form__content-prefix">
                              Бесконтактный чип
                            </h5> -->
                            <div class="m-input-text s-form__name-text">
                              <div class="m-input-text__container input-group mb-3">
                                <span class="input-group-text">Модель</span>
                                <input required v-model="input.model" type="text" class="form-control"
                                  placeholder="Введите наименоание модели чипы" aria-label="" />
                              </div>
                            </div>
                            <template v-for="(block, i) in input.blocks" :key="i">
                              <div class="m-input-text s-form__name-text">
                                <div class="m-input-text__container input-group mb-3">
                                  <p class="input-group-text">
                                    <strong>блок № </strong><input required type="text" v-model="block.blockNum" />
                                  </p>
                                  <input required v-model="block.blockInfo" type="text" class="form-control"
                                    placeholder="Введите информацию для заполнения блока" />
                                  <button @click="blockDel(i)" type="button" class="btn btn-danger">
                                    x
                                  </button>
                                </div>
                              </div>
                            </template>
                            <button @click="blockAdd" type="button" class="s-form__name-add btn btn-primary">
                              Добавить блок
                            </button>
                            <!-- <button
                              v-if="isAddBlock"
                              @click="blockSave"
                              type="button"
                              class="s-form__name-save btn btn-success"
                            >
                              Сохранить информацию блока
                            </button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseInnerTwo" aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseOne">
                            Биты доступа трейлеров
                          </button>
                        </h2>
                        <div id="panelsStayOpen-collapseInnerTwo" class="accordion-collapse collapse">
                          <div class="accordion-body">
                            <div class="m-input-text s-form__name-text">
                              <template v-for="(byte, index) in input.byts" :key="index">
                                <div class="m-input-text__container input-group mb-3">
                                  <span class="input-group-text">
                                    <input required v-model="byte.id" type="text" class="form-control"
                                      placeholder="Введите номер блока" />
                                  </span>
                                  <input v-model="byte.alpha" type="text" class="form-control"
                                    placeholder="Введите alpha" />
                                  <input v-model="byte.key" type="text" class="form-control"
                                    placeholder="Бит доступа" />
                                  <input v-model="byte.beta" type="text" class="form-control"
                                    placeholder="Введите beta" />
                                  <button @click="byteDel(index)" type="button" class="btn btn-danger">
                                    x
                                  </button>
                                </div>
                              </template>
                            </div>
                            <button @click="byteAdd" type="button" class="s-form__name-add btn btn-primary">
                              Добавить сектор
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="accordion">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseInnerThree"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            Выгрузка клиенту БД
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseInnerThree"
                          class="accordion-collapse collapse"
                        >
                          <div class="accordion-body">
                            <div class="s-form__name-container d-flex">
                              <div class="s-form__name-item">
                                <div class="m-input-text s-form__name-text">
                                  <div
                                    class="m-input-text__container input-group mb-3"
                                  >
                                    <span class="input-group-text"
                                      >Серия карты</span
                                    >
                                    <input required
                                      v-model="input.seria"
                                      type="text"
                                      class="form-control"
                                      placeholder="Введите серию карты"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="s-form__name-item">
                                <div class="m-input-text s-form__name-text">
                                  <div
                                    class="m-input-text__container input-group mb-3"
                                  >
                                    <span class="input-group-text">1. uid</span>
                                    <input required
                                      v-model="input.uid[0]"
                                      type="text"
                                      class="form-control"
                                      placeholder="Введите 1. uid"
                                    />
                                  </div>
                                </div>
                                <div class="m-input-text s-form__name-text">
                                  <div
                                    class="m-input-text__container input-group mb-3"
                                  >
                                    <span class="input-group-text"
                                      >1. number</span
                                    >
                                    <input required
                                      v-model="input.graf.number[0]"
                                      type="text"
                                      class="form-control"
                                      placeholder="Введите 1. number"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="s-form__name-item">
                                <div class="m-input-text s-form__name-text">
                                  <div
                                    class="m-input-text__container input-group mb-3"
                                  >
                                    <span class="input-group-text">2. uid</span>
                                    <input required
                                      v-model="input.uid[1]"
                                      type="text"
                                      class="form-control"
                                      placeholder="Введите 1. uid"
                                    />
                                  </div>
                                </div>
                                <div class="m-input-text s-form__name-text">
                                  <div
                                    class="m-input-text__container input-group mb-3"
                                  >
                                    <span class="input-group-text"
                                      >2. number</span
                                    >
                                    <input required
                                      v-model="input.graf.number[1]"
                                      type="text"
                                      class="form-control"
                                      placeholder="Введите 1. number"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo">
                  Графическая персонализация:
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div class="s-form__content-list s-form__content-graf">
                    <div class="s-form__graf">
                      <div class="s-form__graf-items accordion">
                        <!-- <div class="s-form__graf-item"> -->
                        <div class="s-form__graf-item accordion-item">
                          <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseInnerGrafOne" aria-expanded="true" aria-controls="collapseOne">
                              QR-код
                            </button>
                          </h2>
                          <div id="collapseInnerGrafOne" class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <div class="s-form__graf-input">
                                <div class="m-input-text s-form__name-text">
                                  <div class="m-input-text__container input-group mb-3">
                                    <span class="input-group-text">Поле</span>
                                    <input required v-model="input.qr.link" type="text" class="form-control"
                                      placeholder="Данные для QR-кода" />
                                  </div>
                                </div>
                                <div class="m-input-text s-form__name-text">
                                  <div class="m-input-text__container input-group mb-3">
                                    <div class="form-check">
                                      <input v-model="input.qr.isAddNumber" class="form-check-input"
                                        type="checkbox" value="" id="flexCheckChecked" checked />
                                      <label class="form-check-label" for="flexCheckChecked">
                                        + № карты в конце
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="s-form__graf-item accordion-item">
                          <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseInnerGrafTwo" aria-expanded="true" aria-controls="collapseOne">
                              Диапазон
                            </button>
                          </h2>
                          <div id="collapseInnerGrafTwo" class="accordion-collapse collapse show"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <div class="s-form__graf-input">
                                <div class="m-input-text s-form__name-text">
                                  <div class="m-input-text__container input-group mb-3">
                                    <span class="input-group-text">C</span>
                                    <input required :maxlength="maxLength" v-model="input.range.start" type="text"
                                      class="form-control" placeholder="Введите начало диапозона карты" />
                                  </div>
                                </div>
                                <div class="m-input-text s-form__name-text">
                                  <div class="m-input-text__container input-group mb-3">
                                    <span class="input-group-text">По</span>
                                    <input required :maxlength="maxLength" v-model="input.range.end" type="text"
                                      class="form-control" placeholder="Введите конец диапозона карты" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree">
                  Дополнительная информация:
                </button>
              </h2>
              <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div class="s-form__content-list s-form__content-graf">
                    <div class="s-form__graf">
                      <div class="s-form__graf-items">
                        <div class="s-form__graf-item">
                          <h5 class="s-form__content-prefix"></h5>
                          <template v-for="i in input.freefield.length" :key="i">
                            <div class="s-form__graf-input">
                              <div class="m-input-text s-form__name-text">
                                <div class="m-input-text__container input-group mb-3">
                                  <span class="input-group-text">Наименование блока</span>
                                  <input v-model="input.freefield[i - 1].name" type="text" class="form-control"
                                    placeholder="Введите информацию" />
                                </div>
                              </div>
                              <div class="m-input-text s-form__name-text">
                                <div class="m-input-text__container input-group mb-3">
                                  <span class="input-group-text">Информация с блока</span>
                                  <input v-model="input.freefield[i - 1].description" type="text"
                                    class="form-control" placeholder="Введите информацию" />
                                </div>
                              </div>
                            </div>
                            <button @click="freefielDel(i - 1)" type="button" class="freefiel-del btn btn-danger">
                              Удалить блок
                            </button>
                          </template>
                          <div class="s-form__graf-btns">
                            <button @click="freefieldAdd()" type="button" class="btn btn-primary">
                              Добавить поле
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="s-form__form-submit btn btn-success">
            Сохранить шаблон
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, watchEffect } from "vue";
// import { cardType } from "@/store/card.js";
import getCardType from "@/store/card.js";
import { useDockStore } from "@/store/store.js";
import MDrop from "@/components/m-drop.vue";
import MSelect from "@/components/m-select.vue";
import { useRouter } from "vue-router";
import axios from "axios";

const router = useRouter();

const cardType = ref([])
// await getCardType()
// .then((response)=>{
//   cardtype = response.data
// });


const dockStore = useDockStore();

const newLayout = ref(true);

const isAddBlock = ref(false);

const input = ref({
  nameLayout: "Технического задания №",
  sketch: [
    {
      id: 1,
      quant: "",
      amount: "",
      amounts: "",
      face: "",
      back: "",
    },
  ],
  name: "",
  number: "",
  seria: "",
  cardInfo: {
    name: "",
    number: "",
    seria: "",
  },
  model: "Mifare Classic 1K, 4 или 7 byte UID",
  blocks: [
    {
      blockNum: "5",
      blockInfo: "00000000FFFFFFFF0000000022DD22DD",
    },
    {
      blockNum: "32",
      blockInfo: "00000000FFFFFFFF0000000022DD22DD",
    },
    {
      blockNum: "34",
      blockInfo: "00000000FFFFFFFF0000000022DD22DD",
    },
  ],
  byts: [
    {
      id: "0",
      alpha: "",
      beta: "",
      key: "08778F00",
    },
    {
      id: "1",
      alpha: "",
      beta: "",
      key: "08778F00",
    },
    {
      id: "8",
      alpha: "",
      beta: "",
      key: "08778F00",
    },
  ],
  uid: ["04422972AA4F80", "046D5372AA4F80"],
  qr: {
    link: "",
    isAddNumber: true,
  },
  range: {
    start: "",
    end: "",
  },
  freefield: [
    {
      name: "",
      description: "",
    },
  ],
  delete: {
    additionalProp1: "",
    additionalProp2: "",
    additionalProp3: "",
  },
});

const maxLength = ref('')

const arrSketch = ref([]);

const arrCard = ref([]);
const countSketchs = ref(0);

watchEffect(() => {
  input.value.sketch.forEach((element) => {
    element.amounts = (element.amount * element.quant).toString();
  });
  dockStore.addnameLayout(input.value.nameLayout);
  // input.value.range.start =
  //   input.value.seria + "0".repeat(String(input.value.sketch[0].quant).length);
  // input.value.range.end = input.value.seria + input.value.sketch[0].quant;
  // console.log(input.value.range.start, input.value.range.end);
  dockStore.reassigningQR(input.value.qr);
  dockStore.reassigningRange(input.value.range);
  dockStore.reassigningModel(input.value.model);
  dockStore.reassigningBits(input.value.byts);

});

onBeforeMount(() => {
  if (dockStore.getActiveDock.nameLayout != undefined) {
    newLayout.value = false;
    let objectKeys = Object.keys(dockStore.getActiveDock);
    for (let i = 0; i < objectKeys.length; i++) {
      const element = objectKeys[i];
      input.value[element] = dockStore.getActiveDock[element];
    }
  }
  if (dockStore.getSketchs.length == 0) {
    let sketch = {
      id: countSketchs.value + 1,
      amount: "",
      amounts: "",
      face: "",
      back: "",
      quant: "",
    };
    arrSketch.value.push(sketch);
    return;
  } else {
    arrSketch.value = dockStore.getSketchs;
  }
});

onMounted(async () => {
  await getCardType()
    .then((response) => {
      arrCard.value = response
      // console.log(arrCard.value);
    });
  countSketchs.value = 1;
  // // console.log(arrCard.value );
  input.value.sketch.forEach((element) => {
    element.amounts = (element.amount * element.quant).toString();
  });
});

//Добавить эскиз
function sketchsAdd() {
  let sketch = {
    id: countSketchs.value + 1,
    amount: "",
    amounts: "",
    quant: "",
    face: "",
    back: "",
  };
  arrSketch.value.push(sketch);
  input.value.sketch.push(sketch);
  countSketchs.value++;
}

//удаляем эскиз из облака
function sketchsRemove() {
  dockStore.removeSketch(arrSketch.value[countSketchs.value--]);
  arrSketch.value.splice(countSketchs.value, 1);
}

//Добавляем Эскиз лицевой стороны
function handlerFaceImage(image) {
  // console.log(image);
  // console.log(input.value.sketch);
  input.value.sketch.forEach((element) => {
    if (element.id == image[1]) {
      // console.log(image);
      element.face = image[0];
    }
  });
}
//Добавляем Эскиз задней стороны
function handlerBackImage(image) {
  input.value.sketch.forEach((element) => {
    if (element.id == image[1]) {
      // console.log(image[0]);
      element.back = image[0];
    }
  });
}

function removeImage(item) {
  if (input.value.sketch[item[1] - 1].back == item[0]) {
    input.value.sketch[item[1] - 1].back = ""
  } else if (input.value.sketch[item[1] - 1].face == item[0]) {
    input.value.sketch[item[1] - 1].face = ""
  }
}

function freefieldAdd() {
  let freefieldPattern = {
    name: "",
    description: "",
  };
  input.value.freefield.push(freefieldPattern);
}

function freefielDel(idx) {
  input.value.freefield.splice(idx, 1);
}

function byteAdd() {
  let bytsPattern = {
    id: "",
    alpha: "",
    beta: "",
    key: "",
  };
  input.value.byts.push(bytsPattern);
  input.value.byts.sort((a, b) => {
    return parseInt(a.id) - parseInt(b.id);
  });
}

function byteDel(idx) {
  input.value.byts.splice(idx, 1);
}
//добавляем информацию в блок
function blockAdd() {
  let blockPattern = {
    blockNum: "",
    blockInfo: "",
  };
  input.value.blocks.push(blockPattern);
  isAddBlock.value = !isAddBlock.value;
}

function blockDel(idx) {
  input.value.blocks.splice(idx, 1);
}

//Выбираем имя карты и подставляем нужные значения
function handlerSelect(select) {
  console.log(select);
  
  maxLength.value = select.number_of_characters
  // console.log(select.title);
  // selectedNumber.value = select;
  input.value.name = select.title;
  input.value.seria = select.series;
  dockStore.reassigningCard(select);
  input.value.blocks.forEach((element) => {
    if (element.blockNum == 5) {
      element.blockInfo = select.block_5;
    }
    if (element.blockNum == 32) {
      element.blockInfo = select.block_32;
    }
    if (element.blockNum == 34) {
      element.blockInfo = select.block_34;
    }
  });
}

async function submitForm() {
  dockStore.deletaBits();
  dockStore.reassigningBits(input.value.byts);
  dockStore.deleteSketch();
  input.value.sketch.forEach((element) => {
    dockStore.addSketch(element);
  });
  console.log(input.value);
  if ((input.value.range.end.length !== maxLength.value) && (input.value.range.start.length !== maxLength.value)) {
    alert('Поле с диапазоном имеет менбшую длинну чем необходимо, необходимо символов: '+maxLength.value)
    return
  }
  console.log(input.value);
  let patternJson = {
    name: input.value.nameLayout,
    json_body: input.value,
  };
  if (newLayout.value) {
    // // console.log(patternJson);
    dockStore.replaceActiveDocs(patternJson.json_body)
    await axios
      .post(process.env.base_url + "/api/v1/create_tech_req", patternJson, {
        withCredentials: true,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response);
        setTimeout(() => {
          router.push("/");
        }, 1000);
        alert("Новый шаблон добавлен");
        
      })
      .catch((error) => {
        // console.log("Ошибка", error);
        alert(error.response.data.detail);
      });
    return;
  } else {
    // console.log(patternJson);
    await axios
      .patch(
        process.env.base_url + `/api/v1/tech_reqs/${dockStore.getActiveID}`,
        patternJson,
        {
          withCredentials: true,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        alert("Шаблон обновлен");
      })
      .catch((error) => {
        // console.log("Ошибка", error);
        alert(error.response.data.detail);
      });
  }
  // console.log(input.value);
}
</script>

<style lang="scss" scoped>
.s-form {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-list {
      max-width: 650px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      gap: 10px;
      // h5 {
      //   margin: 10px 0;
      // }
    }

    &-prefix {
      font-size: 16px;
      width: 100%;
    }

    &-qr {
      max-width: 991px;
      margin: 0 auto;
    }
  }

  &__tech {
    max-width: 991px;
    display: flex;
    justify-content: space-between;

    gap: 10px;

    &-check {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      gap: 20px;
    }

    textarea {
      font-size: 14px;
      height: 200px;
      margin-top: auto;
    }
  }

  &__name {
    &-text {
      width: 100%;
    }

    &-container {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-item {
      &:nth-child(1) {
        width: 100%;
      }
    }

    &-select {
      width: 100%;
    }
  }

  &__sketch {
    &-items {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .s-form__content-prefix {
        flex: 1;
      }

      span {
        display: block;
        margin-top: 10px;
      }
    }

    &-btn {
      display: flex;
      gap: 10px;
    }
  }

  &__graf {
    &-items {
      display: flex;
      gap: 10px;

      .s-form__graf-item {
        flex: 1;
      }
    }

    &-item {
      &:last-child {
        flex: 1;
      }
    }
  }

  .form-floating>.form-select {
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
  }

  .freefiel {
    &-del {
      width: 50%;
      margin-bottom: 10px;
    }
  }

  .input-group-text {
    strong {
      margin-right: 10px;
    }
  }
}
</style>
