<template>
  <div class="s-cardtype m-auto">
    <form @submit.prevent="sendForm()" class="s-cardtype__form d-flex flex-column gap-10">
      <h1 class="s-cardtype__title h3 mb-3 fw-normal">Добавление новой карты</h1>
      <div class="s-cardtype__form-container">
        <div class="s-cardtype__form-input form-floating">
          <input required type="text" v-model="input.zk_type" class="s-cardtype__form-input form-control"
            placeholder="Введите ваш zk_type" />
          <label class="s-cardtype__form-label" for="floatingInput">zk_type</label>
        </div>
        <div class="s-cardtype__form-input form-floating">
          <input required type="text" v-model="input.title" class="s-cardtype__form-input form-control"
            placeholder="Введите наименование карты" />
          <label class="s-cardtype__form-label" for="floatingPassword">
            Имя карты
          </label>
        </div>
        <div class="s-cardtype__form-input form-floating">
          <input required type="text" v-model="input.series" class="s-cardtype__form-input form-control"
            placeholder="Введите серию карты" />
          <label class="s-cardtype__form-label" for="">
            Серия карты
          </label>
        </div>
        <div class="s-cardtype__form-input form-floating">
          <input required type="text" v-model="input.block_5" class="s-cardtype__form-input form-control"
            placeholder="Введите блок №5" />
          <label class="s-cardtype__form-label" for="">
            Блок №5
          </label>
        </div>
        <div class="s-cardtype__form-input form-floating">
          <input required type="text" v-model="input.block_32" class="s-cardtype__form-input form-control"
            placeholder="Введите блок №32" />
          <label class="s-cardtype__form-label" for="">
            Блок №32
          </label>
        </div>
        <div class="s-cardtype__form-input form-floating">
          <input required type="text" v-model="input.block_34" class="s-cardtype__form-input form-control"
            placeholder="Введите блок №34" />
          <label class="s-cardtype__form-label" for="">
            Блок №34
          </label>
        </div>
        <div class="s-cardtype__form-input form-floating">
          <input required type="number" v-model="input.number_of_characters" class="s-cardtype__form-input form-control"
            placeholder="Введите кол-во символов" />
          <label class="s-cardtype__form-label" for="">
            Кол-во символов
          </label>
        </div>
      </div>
      <button class="s-cardtype__form-btn btn btn-primary py-2" type="submit">
        сохранить
      </button>
    </form>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, watchEffect } from "vue";
const input = ref({
  title: "",
  zk_type: "",
  series: "",
  block_5: "",
  block_32: "",
  block_34: "",
  number_of_characters: 0
});

async function sendForm() {
  // console.log(input.value);
  await axios
    .post(process.env.base_url + "/api/v1/create_card_type", input.value, {
      withCredentials: true,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // // console.log(response.data.access_token);
      alert("Сохранено");
      window.location.reload()
      // router.push("/login");
    })
    .catch((error) => {
      // console.log(error);
      alert("Ошибка сохранения");
    });
}
</script>

<style lang="scss" scoped>
.s-cardtype {
  &__form {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
</style>