<template>
  <section class="s-help">
    <div class="s-help__container mt-10">
      <h2 class="s-help__title">
        Инструкция по применению формы создания тех. задания
      </h2>
      <div class="s-help__content">
        <h3 class="s-help__content-subtitle">Главный экран</h3>
        <div class="s-help__content-img">
          <img src="@/assets/images/s-help/1.png" />
        </div>
        <div class="s-help__content-text">
          <p>
            1.Указывается наименование технического задание, его вы можете
            указывать произвольное.<br />

            2.Указывается эскиз изображения в формате png, jpeg,jpg. Он
            переводится в формат base64 и в таком виде попадает в json объект.
            Размеры необходимо регулировать напрямую с изображения. <br />
            3.Указывается кол-во карт. Принимаются только целые и цифровые
            значения. 4.Указывает стоимость за единицу карты. Принимаются только
            целые и цифровые значения. <br />
            5.Рассчитывается автоматически, но при необходимости возможно
            вписать свою сумму. Принимаются только целые и цифровые значения.
            <br />
            6.Добавляется новый эскиз, по которому необходимо повторить ввод
            всех полей, в случае ненадобности второго эскиза имеется кнопка
            удаляющая его.
          </p>
        </div>
        <h3 class="s-help__content-subtitle">
          Технические требования на изготовление пластиковых карт
        </h3>
        <div class="s-help__content-img">
          <img src="@/assets/images/s-help/2.png" />
        </div>
        <div class="s-help__content-text">
          <p>
            1. Выбор наименования карты. При выборе автоматически подтягивает
            наименование карты (п.2) и серия карты ( п.3). <br />
            2. Заполняется наименование карты. При выборе в п. 1 подтягивается
            автоматически, но при необходимости возможно поменять. <br />
            3. Заполняется серия карты. При выборе в п. 1 подтягивается
            автоматически, но при необходимости возможно поменять. <br />
            4.1 Графа бесконтактного чипа. <br />
            4.2 Модель стоит по дефолтному значению, при необходимости возможно
            поменять. <br />
            4.3, 4.4, 4.5 блоки чипа, номера блоков по дефолту, информация в них
            заполняется самостоятельно <br />
            4.6 кнопка добавляет новый блок, информацию о нем и ставить его в
            порядок по возрастанию <br />
            5.1 графа заполнения битов доступа трейлеров<br />
            5.2, 5.3, 5.4 - биты которые необходимы для заполнения по дефолту,
            ключ заполняется администрацией<br />
            5.5 добавляет новый бит и расставляет в порядке возрастания<br />
          </p>
        </div>
        <h3 class="s-help__content-subtitle">
          Графическая персонализация и дополнительная информация
        </h3>
        <div class="s-help__content-img">
          <img src="@/assets/images/s-help/3.png" />
        </div>
        <div class="s-help__content-text">
          <p>
            1. Блок с Графической персонализацией <br />
            2.1 QR-код<br />
            2.2. В связи с тем что существует выбор между различной информацией
            которую необходу заполнять в графу qr-код карты, вам предлагается
            вручную это сделать дабы учесть актулальной ссылкой или же
            алгаритмом его заполнения <br />
            2.3 Чек-бокс по которому выставляется необходимость добавлять в
            конце ссылки\алгоритма номер карты или нет<br />
            3.1,3.2 Диапозон, выставляется автиматически изходя из выставленых
            кол-во карт на главном экрана и начинается с серии указанной в графе
            "Технические требования на изготовление пластиковых карт"<br />
            4. Блок с дополнительной информацией на карте, заполняется
            опционально. Необходимим для отображения на карте какого либо
            дополнительного поля <br />
            5.1,5.2 Поля для наименования дополнительного поля и информвции
            которая в нем может отображаться <br />
          </p>
        </div>
        <h3 class="s-help__content-subtitle">Кнопка сохранить обновляет шаблон и подгружает на сервер уже измененный шаблон</h3>
      </div>
    </div>
    <div class="s-help__accordion accordion mt-10" id="accordionExample">
      <h2 class="s-help__accordion-title">
        Методы доступные заводу-изготовителю
      </h2>
      <div class="s-help__accordion-item accordion-item">
        <h2 class="s-help__accordion-header accordion-header">
          <button
            class="s-help__accordion-button accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            spec.mypay.tech/api/v1/get_card_number
          </button>
        </h2>
        <div
          id="collapseOne"
          class="s-help__accordion-collapse accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="s-help__accordion-body accordion-body">
            <code>POST-</code>запрос который принимает id технического задания, и mifare_id который должен быть уникальный. Возвращает уникальный номер карты который высчитывает по началу диапозону что указан в техническом задании, и идет по порядку. Если mifare_id не актуальный, то метод возвращает тот же номер карты что ему и был уже присвоен.
          </div>
        </div>
      </div>
      <div class="s-help__accordion-item accordion-item">
        <h2 class="s-help__accordion-header accordion-header">
          <button
            class="s-help__accordion-button accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            spec.mypay.tech/api/v1/tech_reqs/{id}
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="s-help__accordion-collapse accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="s-help__accordion-body accordion-body">
            <code>GET -</code>запрос для получения полного списка карт по переданному {id} технического задания.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
.s-help {
  &__content {
    &-subtitle {
      margin-top: 15px;
    }
    &-text {
      text-align: left;
      margin-top: 15px;
    }
    &-img {
      img {
        max-width: 750px;
      }
    }
  }
}
</style>