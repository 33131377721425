<template>
  <SHeader />
  <main class="container mt-10 mb-10">
    <SAdmin />
  </main>
  <SFooter />
</template>

<script>
import SAdmin from "@/components/s-admin.vue";
import SHeader from "@/components/s-header.vue";
import SFooter from "@/components/s-footer.vue";

// import MInputText from '@/components/m-form.vue';

export default {
  components: {
    SAdmin,
    SHeader,
    SFooter,
  },
};
</script>
