import { createRouter, createWebHistory } from 'vue-router';
import Cookies from "js-cookie";
import homePage from '../pages/index.vue';
import createPage from '../pages/create.vue';
import prewiewPage from '../pages/preview.vue'
import helpPage from '../pages/help.vue'
import loginPage from '../pages/login.vue'
import adminPage from '../pages/admin.vue'
import historyPage from '../pages/history.vue'
import NotFound from '../pages/404.vue'

const routes = [
  {
    path: '/create',
    name: 'Техническое задание',
    component: createPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    name: 'Главная',
    component: homePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/preview',
    name: 'Предпросмотр',
    component: prewiewPage,
    meta: { requiresAuth: true },
  },
  {
    path:'/help',
    name:'Помощь',
    component: helpPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Вход',
    component: loginPage,
  },
  {
    path: '/history',
    name: 'История',
    component: historyPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin',
    name: 'Админ. панель',
    component: adminPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = (Cookies.get('access_token') != undefined)/* Ваша логика проверки аутентификации, например, this.$store.state.isAuthenticated */;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !isAuthenticated) {
    next('/login'); // Перенаправляем на страницу логина, если попытка доступа к защищенной странице без аутентификации
  } else {
    next(); // Разрешаем доступ к маршруту
  }
});

export default router;
