<template>
  <div class="m-logout">
    <button @click="logout()" class="m-logout__btn btn py-2">
      <span class="m-logout__btn-span material-symbols-outlined"> logout </span>
    </button>
  </div>
</template>

<script setup>
import axios from "axios";
import router from "@/router/index";

async function logout() {
  await axios
    .get(process.env.base_url + "/api/v1/logout", {
      withCredentials: true,
    })
    .then((response) => {
      router.push("/login")
      alert("Вы вышли из системы");
      router.push("/login");
    })
    .catch((error) => {});
}
</script>

<style scoped lang="scss">
.m-logout{
  margin-left: auto;
  &__btn{
    &-span{
      color: #fff;
    }
  }
}
</style> 