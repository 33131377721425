import axios from "axios";

export default async function getCardType() {
  let cardTypes;
  await axios
    .get(process.env.base_url + "/api/v1/all_cards_types", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      cardTypes = response.data;
      return response;
      // console.log(cardTypes);
    })
    .catch((error) => {
      cardTypes = error;
      console.error(error);
    });
  return cardTypes;
}