<template>
  <footer
    class="s-footer footer mt-auto py-3 navbar navbar-dark bg-dark shadow-sm "
  >
    <div class="s-footer__container container">
      <ul class="s-footer__links list-unstyled">
        <router-link to="/" class="text-white">Главная</router-link>
        <router-link to="/create" class="text-white"
          >Техническое задание</router-link
        >
        <router-link to="/preview" class="text-white">Превью</router-link>
        <router-link to="/help">Помощь</router-link>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  .list-unstyled{
  display: flex;
  gap: 15px;
}
</style>