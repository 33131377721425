<template>
  <div class="s-register m-auto">
    <form
      @submit.prevent="sendForm()"
      class="s-register__form d-flex flex-column gap-10"
    >
      <h1 class="s-register__title h3 mb-3 fw-normal">Регистрация</h1>
      <div class="s-register__form-container">
        <div class="s-register__form-input form-floating">
          <input
            type="text"
            v-model="input.username"
            class="s-register__form-input form-control"
            placeholder="Введите ваш логин"
          />
          <label class="s-register__form-label" for=" floatingInput"
            >Логин</label
          >
        </div>
        <div class="s-register__form-input form-floating">
          <input
            type="password"
            v-model="input.password"
            class="s-register__form-input form-control"
            placeholder="Введите ваш пароль"
          />
          <label
            v-if="input.password.length == 0 || input.password.length >= 5"
            class="s-register__form-label"
            for="floatingPassword"
          >
            Пароль
          </label>
          <label
            v-if="input.password.length != 0 && input.password.length <= 5"
            class="s-register__form-label"
            for="floatingPassword"
          >
            Пароль должен быть не менее 5 символов
          </label>
        </div>
        <div class="s-register__form-select">
          <select
            v-model="selectRole"
            class="form-select"
            aria-label="Default select example"
          >
            <template v-for="role in rolesArr" :key="role">
              <option :value="role">{{ role.role }}</option>
            </template>
          </select>
        </div>
      </div>
      <button class="s-register__form-btn btn btn-primary py-2" type="submit">
        Регистрация
      </button>
    </form>
  </div>
</template>

<script setup>
import axios from "axios";
import Cookies from "js-cookie";
import { ref, watchEffect } from "vue";
import router from "@/router";
const input = ref({
  username: "",
  password: "",
  role: "сотрудник АРПС",
  role_id: 2,
});

const rolesArr = ref([
  {
    role: "superuser",
    role_id: 1,
  },
  {
    role: "сотрудник АРПС",
    role_id: 2,
  },
  {
    role: "Завод",
    role_id: 3,
  },
]);

const selectRole = ref({
  role: "сотрудник АРПС",
  role_id: 2,
});

watchEffect(() => {
  input.value.role = selectRole.value.role
  input.value.role_id = selectRole.value.role_id
});

const expires = new Date();
expires.setMinutes(expires.getMinutes() + 10);
async function sendForm() {
  // console.log(input.value);
  await axios
    .post(process.env.base_url + "/api/v1/register", input.value, {
      withCredentials: true,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // // console.log(response.data.access_token);
      alert("Регистрация прошла успешно");
      window.location.reload()
      // router.push("/login");
    })
    .catch((error) => {
      // console.log(error);
      alert("Ошибка Регистрации");
    });
}
</script>

<style lang="scss" scoped>
.s-register {
  &__form {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
</style>