<template>
  <div class="m-add-block__item col">
    <div class="m-add-block__preview card shadow-sm">
      <div class="m-add-block__preview-top">
        <img
          height="225"
          src="https://placehold.jp/14/0091eb/ffffff/412x225.png?text=%D0%A1%D0%BE%D0%B7%D0%B4%D0%B0%D1%82%D1%8C%20%D0%BD%D0%BE%D0%B2%D0%BE%D0%B5"
          class="rounded mx-auto d-block"
        />
      </div>
      <div class="m-add-block__preview-bottom card-body">
        <div
          class="m-add-block__preview-btn d-flex flex-column gap-10 align-items-center"
        >
          <button
            data-bs-toggle="modal"
            :data-bs-target="`#${nameBlock}`"
            type="button"
            class="m-add-block__btn btn btn-sm btn-outline-secondary"
          >
            Добавить задание
          </button>
        </div>
      </div>
    </div>
    <APopup title="Добавления нового шаблона" :id="nameBlock">
      <div class="m-add-block__popup">
        <div class="m-add-block__popup-container">
          <div class="m-add-block__popup-content">
            <div class="input-group mb-3">
              <span class="input-group-text"
                >Наименование для тех. задания</span
              >
              <input type="text" class="form-control" v-model="nameNewBlock" />
            </div>
          </div>
          <div class="m-add-block__popup-btns">
            <button
              type="button"
              class="m-block__popup-btn btn btn-primary"
              data-bs-dismiss="modal"
              @click="crateBlock()"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </APopup>
  </div>
</template>

<script setup>
import axios from "axios";
import APopup from "@/components/a-popup.vue";
import { ref } from "vue";
import { useDockStore } from "@/store/store.js";
import { useRouter } from "vue-router";

const router = useRouter();

const dockStore = useDockStore();
const emit = defineEmits(["addNewItem"]);

const nameBlock = ref("m-add-block");
const nameNewBlock = ref("");

const patternJson = ref({
  name: "",
  json_body: {
    nameLayout: "",
    sketch: [
      {
        id: 1,
        quant: "",
        amount: "",
        amounts: "",
        face: "",
        back: "",
      },
    ],
    name: "",
    number: "",
    seria: "",
    cardInfo: {
      name: "",
      number: "",
      seria: "",
    },
    model: "Mifare Classic 1K, 4 или 7 byte UID",
    blocks: [
      {
        blockNum: "5",
        blockInfo: "",
      },
      {
        blockNum: "32",
        blockInfo: "00000000FFFFFFFF0000000022DD22DD",
      },
      {
        blockNum: "34",
        blockInfo: "00000000FFFFFFFF0000000022DD22DD",
      },
    ],
    byts: [
      {
        id: "0",
        alpha: "",
        beta: "",
        key: "08778F00",
      },
      {
        id: "1",
        alpha: "",
        beta: "",
        key: "08778F00",
      },
      {
        id: "8",
        alpha: "",
        beta: "",
        key: "08778F00",
      },
    ],
    uid: [],
    // graf: {
    //   uid: "d1e283731aff27",
    //   number: ["0000018", "0000026"],
    //   beginning: "",
    //   end: "",
    // },
    qr: {
      link: "",
      isAddNumber: false,
    },
    range: {
      start: "",
      end: "",
    },
    freefield: [
      {
        name: "",
        description: "",
      },
    ],
    delete: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: "",
    },
  },
});

async function crateBlock() {
  patternJson.value.name = nameNewBlock.value;
  patternJson.value.json_body.nameLayout = nameNewBlock.value;
  let body = new Object();
  body = patternJson.value;
  // // console.log(body);

  await axios
    .post(
      process.env.base_url + "/api/v1/create_tech_req",

      patternJson.value,
      {
        withCredentials:true,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      dockStore.purID(response.data.id);
      dockStore.replaceActiveDocs(response.data.json_body);
      dockStore.getActiveDock.nameLayout;
      emit("addNewItem", response.data);
      setTimeout(() => {
        router.push("/create");
      }, 1000);
    })
    .catch((error) => {
      // // console.log("Ошибка", error);
    });
}
</script>
