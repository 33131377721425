<template>
  <div class="s-history">
    <h1 class="s-history__tite">История использования приложения</h1>
    <div class="s-history__container mb-3">
      <!-- <form
        @submit.prevent="searchData()"
        class="s-history__form d-flex"
        role="search"
      >
        <input
          class="s-history__form-input form-control me-2"
          type="search"
          placeholder="Поиск по логину пользователя"
          aria-label="Поиск"
          v-model="search.login"
        />
        <button
          class="s-history__form-btn btn btn-outline-success"
          type="submit"
        >
          Поиск
        </button>
      </form> -->
    </div>
    <h2 v-if="errorData" class="s-history__subtitle">
      Такой пользователь не обнаружен: {{ search.login }}
    </h2>
    <div v-if="sectionData.length != 0" class="s-history__content">
      <h2 class="s-history__subtitle">
        Данные пользователя {{ search.login }}
      </h2>

      <div class="s-history__content-items">
        <template v-for="(item, i) in sectionData" >
          <ul :key="i"
            v-if="item.description != null"
            class="s-history__content-item list-group list-group-horizontal"
          >
            <li class="s-history__content-item list-group-item">
              № {{ sectionData.length - i }}
            </li>
            <li class="s-history__content-item list-group-item">
              {{ item.description }}
            </li>
            <li class="s-history__content-item list-group-item">
              {{ toShortDate(item.created_at) }}
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref,watchEffect } from "vue";

const search = ref({
  login: "",
});

const sectionData = ref([]);
const errorData = ref(false);

watchEffect(() => {
  searchData();
});

async function searchData() {
  await axios
    .get(
      process.env.base_url +
        `/api/v1/users_history?username=${search.value.login}`,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // console.log(response.data);
      sectionData.value = response.data;
      errorData.value = false
      sectionData.value = sectionData.value.filter(
        (obj) => obj.description !== null
      );
    })
    .catch((response, error) => {
      // console.log(response);
      if (response.message == "Request failed with status code 404") {
        errorData.value = true;
        // console.log(errorData.value);
      }
      sectionData.value = [];
      // console.log(error);
    });
}
// async function searchAllData(){
//   await axios
//     .get(
//       process.env.base_url +
//         `/api/v1/all_users_history`,
//       {
//         withCredentials: true,
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((response) => {
//       // console.log(response.data);
//       sectionData.value = response.data;
//       errorData.value = false
//       // console.log(sectionData.value);
//       sectionData.value = sectionData.value.filter(
//         (obj) => obj.description !== null
//       );
//     })
//     .catch((response, error) => {
//       // // console.log(response);
//       // if (response.message == "Request failed with status code 404") {
//       //   errorData.value = true;
//       //   // console.log(errorData.value);
//       // }
//       // sectionData.value = [];
//       // console.log(error);
//     });
// }

const toShortDate = (date) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  const formattedTime = new Date(date).toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate} ${formattedTime}`;
};
// await axios
//   .get(process.env.base_url + "/api/v1/all_tech_reqs", {
//     withCredentials: true,
//     // headers: {
//     //   Authorization:
//     //     "Bearer bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdHJpbmciLCJleHAiOjE2ODkzOTEyNjl9.wEFBySNuDFmuPUW4zoeUwj_4sM875-eOh4OWztIYGaY",
//     //   "Content-Type": "application/json",
//     // },
//   })
//   .then((response) => {
//     // // console.log(response);
//     sectionData.value = response.data;
//     transferData.value = sectionData.value;
//     // emit('dataLoaded', sectionData.value)
//   })
//   .catch((error) => {
//     // // console.log(sectionData.value);
//     sectionData.value = [];
//     transferData.value = [];
//     // console.log(error);
//   });
</script>

<style lang="scss" scoped>
.s-history {
  &__content {
    &-items {
      display: flex;
      flex-direction: column-reverse;
      gap: 10px;
    }
    &-item {
      width: 100%;
    }
  }
}
</style>