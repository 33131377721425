<template>
  <SHeader :mainData="sectionData" @emitSearch="equateData" />
  <main class="container mt-10 mb-10">
    <SMain :requirData="transferData" @reworkData="waitForAction" />
  </main>
  <SFooter />
</template>

<script setup>
import axios from "axios";
import SHeader from "@/components/s-header.vue";
import SFooter from "@/components/s-footer.vue";
import SMain from "@/components/s-main.vue";
import router from "@/router";

import { ref, watchEffect,computed } from "vue";

const isAdmin = ref(false); // Предположим, у вас есть вычисляемое свойство, определяющее, является ли пользователь админом или нет
const sectionData = ref([]);
const transferData = ref("");

// Вычисляемое свойство, которое определяет значение url в зависимости от роли пользователя (isAdmin) и значения из localStorage
const url = computed(() => {
  // Получить значение из localStorage
  const userForm = localStorage.getItem("userForm");

  // Проверить, является ли пользователь админом или если userForm равно "Admin"
  if (isAdmin.value || userForm === "admin") {
    return "/api/v1/all_tech_reqs_by_username";
  } else {
    return `/api/v1/all_tech_reqs_by_username`;
  }
});

watchEffect(() => {
  waitForAction();
});

function waitForAction() {
  setTimeout(() => {
    requestData();
  }, 500);
}
async function requestData() {
  // // console.log(process.env);
  sectionData.value = [];
  transferData.value = [];
  await axios
    .get(process.env.base_url + url.value, {
      withCredentials: true,
    })
    .then((response) => {
      // // console.log(response);
      sectionData.value = response.data;
      transferData.value = sectionData.value;
      // emit('dataLoaded', sectionData.value)
    })
    .catch((error) => {
      // // console.log(sectionData.value);
      sectionData.value = [];
      transferData.value = [];
      // console.log(error);
    });
}
function equateData(el) {
  transferData.value = el;
  // console.log(el);
}
</script>
