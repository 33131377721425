<template>
  <section class="s-preview">
    <div class="s-preview__container">
      <h1 class="s-preview__title">
        <!-- <b>{{ sectionData.activeDock }}</b> -->
        <b>{{ sectionData.activeDock.nameLayout }}</b>
      </h1>
      <div class="s-preview__sketch">
        <h2 class="s-preview__sketch-title h2-title">
          1. Эскиз (оригинал-макет)
        </h2>
        <template v-for="item,i in sectionData.activeDock.sketch" :key="i">
          <h3 class="s-preview__sketch-subtitle subtitle">Эскиз №{{ i + 1 }}</h3>
          <div class="s-preview__sketch-img">
            <div class="s-preview__img-face">
              <img :src="item.face" alt="face" />
            </div>
            <div class="s-preview__img-back">
              <img :src="item.back" alt="back" />
            </div>
          </div>
        </template>
        <p class="s-preview__sketch-prefix">
          Цвета и шрифты в соответствии с предоставленным макетом формата «.AI».
        </p>
      </div>
      <div class="s-preview__tech">
        <h2 class="s-preview__tech-title h2-title">
          2. Технические требования на изготовление пластиковых карт
        </h2>
        <div class="s-preview__tech-tables">
          <table class="s-preview__tech-table">
            <tr>
              <td>
                №<br />
                п/п
              </td>
              <td>Наименование</td>
              <td>
                Ед.<br />
                изм.
              </td>
              <td>Кол-во</td>
              <td>
                Цена за ед.<br />
                изм.
              </td>
              <td>
                Стоимость<br />
                с НДС (руб.)
              </td>
            </tr>
            <template v-for="item, i in sectionData.activeDock.sketch" :key="i">
              <tr>
                <td>{{ i+1 }}.</td>
                <td>{{ sectionData.activeDock.name }}</td>
                <td>шт.</td>
                <td>{{ item.quant }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.amounts }}</td>
              </tr>
            </template>
          </table>
          <div class="s-preview__tech-bigtable">
            <table>
              <tr>
                <td class="subtitle-title" colspan="5">
                  Интерфейсы и электронная персонализация
                </td>
              </tr>
              <tr>
                <td rowspan="2">Бесконтактный чип</td>
                <td>Модель:</td>
                <td colspan="5">{{ sectionData.activeDock.model }}</td>
                <!-- <td>Ячейка 4</td>  -->
                <!--<td>Ячейка 5</td> -->
              </tr>
              <tr>
                <td>Данные для записи:</td>
                <td class="bigcell" colspan="5">
                  Тип карты: "{{ sectionData.activeDock.name }}"
                  <template v-for="block, i in sectionData.activeDock.blocks" :key="i">
                    блок №{{ block.blockNum }} имеет значение
                    {{ block.blockInfo }}
                  </template>
                </td>
                <!--<td>Ячейка 8</td> -->
              </tr>
              <tr>
                <td>Ключевая информация</td>
                <td>Данные для записи:</td>
                <td colspan="5" class="bigcell">
                  Ключевая информация для «закрытия» сектора № 1 (блоки №
                  4,5,6,7) и сектора № 8 (блоки № 32,33,34,35) будет
                  предоставлена по электронным каналам связи в зашифрованном
                  виде. <br />
                  Биты доступа трейлеров:
                  <template v-for="(byte, i) in sectionData.activeDock.byts" :key="i">
                    <br />№{{ byte.id }}, A:{{ byte.alpha }}, B:{{ byte.beta }},
                    key:{{ byte.key }}
                  </template>
                </td>
              </tr>
              <tr>
                <td>Выгрузка клиенту БД</td>
                <td class="bigcell" colspan="4">
                  Файл соответствия печатного транспортного номера (исходя из
                  условий пункта «Нумерация» раздела «Графическая
                  персонализация» данной таблицы) и Mifare UID в HEX (прямой
                  порядок чтения). Формат предоставляемого файла «*.csv», <br />
                  пример:<br />
                  uid;number
                  <!-- <div> -->
                  <!-- <template v-for="i in sectionData.clientData.uid.length" :key="i"> -->
                  <p>
                    <!-- {{ sectionData.activeDock.uid }}, -->
                    <!-- {{ sectionData.activeDock.cardInfo.seria -->
                    <!-- }}{{ sectionData.activeDock.clientData.number[0] }} -->
                  </p>
                  <p>
                    <!-- {{ sectionData.activeDock.clientData.uid[1] }}, -->
                    <!-- {{ sectionData.activeDock.cardInfo.seria -->
                    <!-- }}{{ sectionData.activeDock.clientData.number[1] }} -->
                  </p>
                  <!-- </template> -->
                  <!-- </div> -->
                  Где:<br />
                  {{ sectionData.activeDock.seria }} - серия карты<br />
                  000001 и 000002 – порядковый номер карты; 8 и 6– контрольная
                  сумма (алгоритм Луна - https://go.dev/play/p/IjCLjw2KmXJ).
                </td>
              </tr>
              <tr>
                <td class="subtitle-title" colspan="5">
                  Графическая персонализация:
                </td>
              </tr>
              <tr>
                <td><b>QR-код</b></td>
                <td class="bigcell" colspan="5">
                  {{ sectionData.qr.link }}; {{ sectionData.qr.isAddNumber ? 'Добавлять в конце номер': 'Не добавлять в конце номер'}},
                </td>
              </tr>
              <tr>
                <td rowspan="2">
                  <b>Нумерация</b>
                </td>
                <td rowspan="2">{{ sectionData.cardInfo.name }}</td>
                <td rowspan="2"><b>Диапазон:</b></td>
                <td>с №</td>
                <td>{{ sectionData.range.start }}</td>
              </tr>
              <tr>
                <td>по №</td>
                <td>{{ sectionData.range.end }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <p class="s-preview__prefix">
        Техническое задание составлено в двух экземплярах, имеющих одинаковую
        юридическую<br />
        силу, по одному для каждой из Сторон, и является неотъемлемой частью
        Договора.
      </p>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useDockStore } from "@/store/store.js";

const dockStore = useDockStore();

const sectionData = ref({
  cardInfo: {},
  sketchsStore: [],
  blocks: {},
  bits: [],
  clientData: {
    uid: ["04422972AA4F80", "046D5372AA4F80"],
    number: ["0000018", "0000026"],
  },
  qr: {},
  activeDock:{},
  range: {
    start: "",
    end: "",
  },
});

onMounted(() => {
  // sectionData.value.cardInfo = dockStore.getCardInfo;
  // sectionData.value.sketchsStore = dockStore.getSketchs;
  // sectionData.value.blocks = dockStore.getBlock;
  // sectionData.value.bits = dockStore.getBits;
  // sectionData.value.activeDock = dockStore.getActiveDock
  // // sectionData.value.clientData = dockStore.getClientData;
  // sectionData.value.qr = dockStore.getQr;
  // sectionData.value.range = dockStore.getRange;
  // sectionData.value.model = dockStore.getModel;
  sectionData.value.activeDock = dockStore.getActiveDock;
  // // console.log(sectionData.value.clientData.uid[0]);
  sectionData.value.qr = sectionData.value.activeDock.qr
  sectionData.value.range = sectionData.value.activeDock.range
  // // console.log(sectionData.value.qr);
  // // console.log(sectionData.value.activeDock);
  // for (let i = 0; i < sectionData.value.sketchsStore.length; i++) {
  // const element = sectionData.value.sketchsStore[i];
  // // console.log(URL.createObjectURL(element.face));
  // // console.log(element.face);
  // element.faceLink = URL.createObjectURL(element.face)
  // element.backLink = URL.createObjectURL(element.back)
  // }
  // // console.log(sectionData.value.sketchsStore[0].face);
});
</script>

<style lang="scss" scoped>
.s-preview {
  max-width: 1100px;
  margin: 0 auto;
  background-color: #ffffff; /* цвет фона страницы */
  padding: 30px 0;
  line-height: 100%;
  &__title {
    font-size: 14pt;
  }
  &__sketch {
    &-title {
    }
    &-subtitle {
      font-size: 14pt;
    }
    &-prefix {
      font-size: 14pt;
      margin: 0;
      margin-top: 10px;
    }
    &-img {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      img {
        width: 100%;
      }
    }
  }
  &__tech {
    &-tables {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &-table {
      margin-top: 20px;
    }
  }
  &__prefix {
    margin-top: 5px;
    font-size: 12pt;
  }
  .h2-title {
    margin-left: 50px;
    text-align: left;
    font-size: 14pt;
  }
  table {
    border: #000 solid 1px;
    width: 100%;
    td {
      font-size: 10pt;
      border: #000 solid 1px;
      &:nth-child(1) {
        width: 35px;
      }
      &:nth-child(2) {
        width: 200px;
      }
    }
  }
  .subtitle-title {
    background: #c2c2c2;
    color: #000;
    text-align: left;
    padding-left: 10px;
  }
  .bigcell {
    padding: 5px;
    text-align: left;
  }
  p {
    margin: 0;
    padding: 0;
  }
}
</style>