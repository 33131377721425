import { defineStore } from 'pinia';


export const useDockStore = defineStore('dockStore', {
  state: () => ({
    nameLayout:'',
    cardInfo: {
      name: "",
      number: "",
      seria: "",
    },
    sketchsStore: [],
    blocks: [
      {
        blockNum: '5',
        blockInfo: ''
      },
      {
        blockNum: '32',
        blockInfo: '00000000FFFFFFFF0000000022DD22DD'
      },
      {
        blockNum: '34',
        blockInfo: '00000000FFFFFFFF0000000022DD22DD'
      },
    ],
    bits: [],
    model:'Mifare Classic 1K, 4 или 7 byte UID',
    // series: '',
    clientData: {
      uid: ['04422972AA4F80', '046D5372AA4F80'],
      number: ['0000018', '0000026']
    },
    // graphicObject: {
    //   uid: 'd1e283731aff27',
    //   number: '0000018',
    //   beginning: '',
    //   end: '',
    // },
    qr: {
      link: "",
      isAddNumber: false,
    },
    range: {
      start: "",
      end: "",
    },
    freefield:[
      
    ],
    activeDock:{},
    activeID: null
  }),
  getters: {
    getnameLayout: (state) => state.nameLayout,
    getCardInfo: (state) => state.cardInfo,
    getSketchs: (state) => state.sketchsStore,
    getBits: (state) => state.bits,
    getBlock: (state) => state.blocks,
    getClientData: (state) => state.clientData,
    getQr: (state) => state.qr,
    getModel: (state) => state.model,
    getActiveDock: (state) => state.activeDock,
    getActiveID: (state) => state.activeID,
  },
  actions: {
    addnameLayout(text) {
      this.nameLayout = text
    },
    deleteSketch() {
      this.sketchsStore = [];
    },
    addSketch(el) {
      this.sketchsStore.push(el);
    },
    addBlocks(el){
      this.blocks = el
    },
    removeSketch(elem) {
      this.sketchsStore.forEach((element, index) => {
        if (element.id === elem.id) {
          this.sketchsStore.splice(index, 1);
        }
        return;
      });
    },
    reassigningCard(elem) {
      this.cardInfo = elem;
    },
    deletaBits() {
      this.bits = []
    },
    reassigningBits(elem) {
      this.bits = elem;
    },
    reassigningQR(elem) {
      this.gr = elem;
    },
    reassigningRange(elem) {
      this.range = elem;
    },
    reassigningModel(elem){
      this.model = elem
    },
    reassigningName(name){
      this.cardInfo.name = name
      // // console.log(name, 'dd');
    },
    replaceActiveDocs(obj){
      this.activeDock = obj
    },
    purID(id){
      this.activeID = id
    }
  },
});