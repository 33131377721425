<template>
  <div class="m-block col">
    <div class="m-block__container card shadow-sm">
      <div v-if="!isDeleted" class="m-block__preview">
        <img
          height="225"
          :src="`https://placehold.jp/14/62ac6b/ffffff/412x225.png?text=${sectionItem.name}`"
          class="rounded mx-auto d-block"
        />
      </div>

      <!-- <p v-if="item">{{ sectionItem }}</p> -->
      <div class="m-block__body card-body" v-if="!isDeleted">
        <p class="m-block__body-text card-text">
          {{ item.json_body.nameLayout }}
        </p>
        <div
          class="m-block__body-bottom d-flex flex-column gap-10 align-item-center"
        >
          <div class="m-block__body-btn btn-group">
            <button
              type="button"
              class="m-block__btn btn btn-sm btn-outline-secondary"
              data-bs-toggle="modal"
              :data-bs-target="`#${nameBlock}`"
            >
              Просмотр
            </button>
            <button
              type="button"
              class="m-block__btn btn btn-sm btn-outline-secondary"
              @click="editTemplate()"
            >
              Редактировать
            </button>
          </div>
          <button
            type="button"
            class="m-block__btn-del btn btn-danger"
            @click="removeItem(sectionItem)"
          >
            х
          </button>
          <div class="m-block__body-prefixs">
            <small class="m-block__body-prefix text-body-secondary"
              >Создано: {{ toShortDate(sectionItem.created_at) }}</small
            >
            <small class="m-block__body-prefix text-body-secondary"
              >Обновлено: {{ toShortDate(sectionItem.updated_at) }}</small
            >
          </div>
        </div>
      </div>
      <div v-if="isDeleted" class="m-block__preview">
        <img
          src="https://placehold.jp/14/eb0000/ffffff/412x225.png?text=%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%BE%20%D1%83%D1%81%D0%BF%D0%B5%D1%88%D0%BD%D0%BE"
          class="rounded mx-auto d-block"
        />
        <div class="m-block__body card-body">
          <p class="m-block__body-text card-text">
            {{ item.json_body.nameLayout }}
          </p>
          <div
            class="m-block__body-bottom d-flex flex-column gap-10 align-item-center"
          >
            <div class="m-block__body-btn btn-group">
              <button
                type="button"
                class="m-block__btn btn btn-sm btn-outline-secondary"
                data-bs-toggle="modal"
                :data-bs-target="`#${nameBlock}`"
              >
                Просмотр
              </button>
              <button
                type="button"
                class="m-block__btn btn btn-sm btn-outline-secondary"
                @click="editTemplate()"
              >
                Редактировать
              </button>
            </div>
            <button
              type="button"
              class="m-block__btn-del btn btn-danger"
              @click="removeItem(sectionItem)"
            >
              х
            </button>
            <div class="m-block__body-prefixs">
              <small class="m-block__body-prefix text-body-secondary"
                >Создано: {{ toShortDate(sectionItem.created_at) }}</small
              >
              <small class="m-block__body-prefix text-body-secondary"
                >Обновлено: {{ toShortDate(sectionItem.updated_at) }}</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <APopup
      class="m-block__popup"
      title="Информация Тех. Задания"
      :id="nameBlock"
    >
      <div class="m-block__popup-container">
        <div class="m-block__popup-content">
          <div class="m-block__popup-name">
            Наименование тех. задания "<u>{{ item.json_body.nameLayout }}</u
            >""
          </div>
          <div class="m-block__popup-body">
            Было создано: {{ toShortDate(item.created_at) }}<br />
            Было Обновалено: {{ toShortDate(item.updated_at) }}<br />
            Статус: {{ status }}
            <div class="m-block__body-sketch">
              <template v-for="(item, id) in item.json_body.sketch" :key="id">
                <div v-if="item.face">
                  <img class="m-block__sketch-img" :src="item.face" />
                </div>
                <div v-if="item.back">
                  <img class="m-block__sketch-img" :src="item.back" />
                </div>
                <div>
                  Cтоимость одной карты в эскизе №{{ id + 1 }}:
                  {{ item.amount }}
                </div>
                <div>Кол-во карт в эскизе №{{ id + 1 }}: {{ item.quant }}</div>
                <div>
                  Cтоимость всех карт в эскизе №{{ id + 1 }}: {{ item.amounts }}
                </div>
              </template>
            </div>
            Наименование карт: {{ item.json_body.name }}<br />
            Серия карт: {{ item.json_body.seria }}<br />
            Диапозон карт с <code>{{ item.json_body.range.start }}</code> по
            <code>{{ item.json_body.range.end }}</code
            ><br />
            <br />
            <b>Информация по блокам:</b>
            <div class="m-block__body-blocks">
              <template v-for="(block, id) in item.json_body.blocks" :key="id">
                № блока: {{ block.blockNum }}<br />
                Инфо по блоку: <code>{{ block.blockInfo }}</code
                ><br />
              </template>
            </div>
            <br />
            <b>Информация по битам трейлеров:</b>
            <div class="m-block__body-bits">
              <template v-for="(bit, id) in item.json_body.byts" :key="id">
                <p>
                  № Бита: {{ bit.id }} Сектора А "{{ bit.alpha }}", B "{{
                    bit.beta
                  }}",<br />
                  ключу между ними <code>{{ bit.key }}</code>
                </p>
              </template>
            </div>
            <br />
            <div
              v-if="item.json_body.freefield[0].name != ''"
              class="m-block__body-freefield"
            >
              <b>Информация по Свободным полям:</b>
              <template
                v-for="freefield in item.json_body.freefield"
                :key="freefield"
              >
                Наименование: {{ freefield.name }}<br />
                Описание: {{ freefield.description }}
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="m-block__popup-btns modal-footer">
        <button
          type="button"
          class="m-block__popup-btn btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Закрыть
        </button>
      </div>
      <!-- {{ item }} -->
    </APopup>
  </div>
</template>

<script setup>
import axios from "axios";
import APopup from "@/components/a-popup.vue";
import { useDockStore } from "@/store/store.js";
import { ref, toRef, defineProps, onMounted, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  item: {
    type: Object,
    requared: true,
  },
});

onMounted(()=>{
  nameBlock.value = props.item.id
  // console.log(props.item.json_body.range);
})

const emit = defineEmits(["deleteItem"]);
const status = computed(() => {
  if (sectionItem.value.is_deleted) {
    isDeleted.value = sectionItem.value.is_deleted
    return "Удаленный";
  } else if (sectionItem.value.is_archived) {
    return "В Архиве";
  } else {
    return "Активный";
  }
});

const nameBlock = ref("m-block");

const dockStore = useDockStore();

const isDeleted = ref(false);
const sectionItem = toRef(props, "item");
const toShortDate = (date) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  const formattedTime = new Date(date).toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate} ${formattedTime}`;
};

async function removeItem(it) {
  await axios
    .delete(process.env.base_url + `/api/v1/delete_tech_req/${it.id}`, {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
    })
    .then((response) => {
      isDeleted.value = true;
      emit("deleteItem", isDeleted.value);
    })
    .catch((error) => {
      // console.log(error);
    });
}

function editTemplate() {
  // // console.log(props.item);
  dockStore.purID(props.item.id);
  dockStore.replaceActiveDocs(sectionItem.value.json_body);
  setTimeout(() => {
    router.push("/create");
  }, 1000);
  // router.push('/create');
}
</script>

<style lang="scss" scoped>
.m-block {
  position: relative;
  &__body {
    &-bottom {
      gap: 10px;
    }
    &-prefixs {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn {
    &-del {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      line-height: 100%;
      font-size: 20px;
      border-radius: 50%;
      transform: translate(50%, -50%);
      padding: 0;
    }
  }
  &__preview {
    img {
      width: 100%;
    }
  }
  &__popup {
    &-container {
      text-align: left;
    }
  }
  &__sketch {
    &-img {
      max-width: 310px;
      max-height: 450px;
    }
  }
}
</style>