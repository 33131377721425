<template>
  <div class="s-main album py-5 bg-body-tertiary">
    <div class="s-main__container container">
      <div class="s-main__items row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <MAddBlock @addNewItem="waitForAction" />
        <template v-for="i in sectionData" :key="i">
          <MBlock
            class="s-main__item"
            :item="i"
            @deleteItem="waitForAction"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, watch, provide } from "vue";
import MBlock from "@/components/m-block.vue";
import MAddBlock from "@/components/m-add-block.vue";
import { useDockStore } from "@/store/store.js";

const dockStore = useDockStore();

const props = defineProps({
  requirData: {
    type: Object,
    requared: true,
  },
});
const emit = defineEmits(["reworkData"]);

const sectionData = ref(props.requirData);

watch(
  () => props.requirData,
  (newVal) => {
    sectionData.value = newVal;
    // console.log(sectionData.value);
  }
);

function waitForAction(el) {
  // // console.log(el);
  emit("reworkData", el);
}
</script>

<style lang="scss" scoped>
.s-main {
  &__body {
    &-bottom {
      gap: 10px;
    }
    &-prefixs {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
