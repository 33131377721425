<template>
  <div class="s-login m-auto">
    <form
      @submit.prevent="sendForm()"
      class="s-login__form d-flex flex-column gap-10"
    >
      <h1 class="s-login__title h3 mb-3 fw-normal">Войдите</h1>
      <div class="s-login__form-container form-floating">
        <input
          type="text"
          v-model="input.login"
          class="s-login__form-input form-control"
          id="floatingInput"
          placeholder="Введите ваш логин"
        />
        <label class="s-login__form-label" for=" floatingInput">Логин</label>
      </div>
      <div class="s-login__form-container form-floating">
        <input
          type="password"
          v-model="input.password"
          class="s-login__form-input form-control"
          id="floatingPassword"
          placeholder="Введите ваш пароль"
        />
        <label class="s-login__form-label" for="floatingPassword">Пароль</label>
      </div>
      <div class="s-login__form-btns">
        <button class="s-login__form-btn btn btn-primary py-2" type="submit">
          Вход
        </button>
        <!-- <button
          class="s-login__form-btn btn btn-light py-2"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#a-login"
        >
          Регистрация
        </button> -->
      </div>
    </form>
    <!-- <APopup id="a-login">
      <SRegister />
    </APopup> -->
  </div>
</template>

<script setup>
import axios from "axios";
import Cookies from "js-cookie";
import { ref } from "vue";
import router from "@/router";
import APopup from "@/components/a-popup.vue";
import SRegister from "@/components/s-register.vue";

const input = ref({
  login: "",
  password: "",
});
const data = new URLSearchParams();
const expires = new Date();
expires.setMinutes(expires.getMinutes() + 30);

async function sendForm() {
  data.append("username", input.value.login);
  localStorage.setItem("userForm",input.value.login)
  data.append("password", input.value.password);
  await axios
    .post(process.env.base_url + "/api/v1/login", data, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((response) => {
      // // console.log(response.data.access_token);
      // Cookies.set("access_token", response.data.access_token, { expires });
      router.push("/");
    })
    .catch((error) => {
      // console.log(error);
      alert("Ошибка входа");
    });
}
</script>

<style lang="scss">
.s-login {
  width: 600px;
  &__form {
    &-btns {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
</style>