<template>
  <div class="m-drop">
    <div class="m-drop__container">
      <div class="m-drop__content">
        <!-- {{ props.image }} -->
        <span v-if="!droppedImage.link && !props.image" class="m-drop__content-drag" 
          @dragover.prevent="dummyfunction"
          @drop.prevent="dropImage"
          @dragleave="dummyfunction"
          @click="inputZone.click()"
        >
          <p>
            Нажмите или перетащите
          </p>
        </span>
        <input class="m-drop__content-input" 
          ref="inputZone" 
          type="file" 
          accept=".jpg, .png, image/jpeg, image/png" 
          @change="inputImage"
        />
        <div class="m-drop__content-preview">
          <!-- <img class="m-drop__content-img" v-if="droppedImage.link" :src="droppedImage.link" alt="Dropped Image" /> -->
          <img class="m-drop__content-img" v-if="props.image" :src="props.image" alt="">
          <img class="m-drop__content-img" v-if="base64Image && !props.image" :src="decodeBase64ToImage(base64Image)" alt="Base64 Image" />
        </div>

        <!-- <p>{{ props.image }}</p><br> -->
        <div v-if="droppedImage.link || props.image" class="m-drop__content-remove">
          <button @click="removePreview()" type="button" class="m-drop__content-btn btn btn-danger">
            ✕
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRef } from 'vue';

const droppedImage = ref({
  file: null,
  link: null
});
const base64Image = ref(null);
const inputZone = ref(null);

const props = defineProps({
  index: {
    type: Number
  },
  image:{
    type: String,
    default: "sss"
  }
});

const indexDrop = toRef(props, 'index');

const emit = defineEmits(["emitImage","removeImage"]);

function inputImage(event) {
  const files = event.target.files;
  const file = files[0];
  droppedImage.value.file = file;
  droppedImage.value.link = URL.createObjectURL(file);
  // console.log(file);
  convertToBase64(file);
}

function convertToBase64(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    base64Image.value = reader.result;
    emit('emitImage', [base64Image.value, indexDrop.value]);
  };
}

function dummyfunction(event) {
  event.preventDefault();
}

function dropImage(event) {
  const files = event.dataTransfer.files;
  const file = files[0];
  droppedImage.value.file = file;
  droppedImage.value.link = URL.createObjectURL(file);
  // console.log(file);
  inputZone.value = event.target.files;
  convertToBase64(file);
}

function removePreview() {
  emit('removeImage', [props.image, indexDrop.value]);  
  inputZone.value.value = null;
  droppedImage.value.file = null;
  droppedImage.value.link = null;
  base64Image.value = null;
}

function decodeBase64ToImage(base64String) {
  return base64String;
}
</script>

<style scoped lang="scss">
.m-drop {
  &__content {
    &-drag {
      width: 250px;
      height: 250px;
      background-color: #f0f8ff;
      border: 2px #ddd;
      border-style: dashed;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      transition: .3s;
      &:hover {
        background-color: #cee6fb;
      }
    }
    &-input {
      display: none;
    }
    &-img {
      max-width: 310px;
      max-height: 450px;
    }
    &-remove {
      margin-top: 10px;
    }
    &-btn {
      border-radius: 50%;
    }
  }
}
</style>
