import axios from 'axios';
import Cookies from 'js-cookie';
const expires = new Date();
expires.setMinutes(expires.getMinutes() + 10)
export function authMiddleware() {
  // Cookies.set('jwt_token', ".eyJzdWIiOiJzdHJpbmciLCJleHAiOjE2ODkzODE3MTB9.whVBwUlP7lxtkt1ynGHpclYfveQY5Cbdk4ch3aVhpdk", { expires });
  axios.interceptors.request.use((config) => {
    const token = Cookies.get('access_token');
    // // console.log(token);
    if (token) {
      // config.headers.Authorization = `Bearer bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdHJpbmciLCJleHAiOjE2ODkzODE3MTB9.whVBwUlP7lxtkt1ynGHpclYfveQY5Cbdk4ch3aVhpdk`;
      // // console.log(config.headers.Authorization);
    }
    return config;
  });
}
