<template>
  <div class="s-admin">
    <h1 class="s-admin__title">Методы доступные только Администраторам</h1>
    <div class="s-admin__content">
      <div class="s-admin__content-btns">
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio0" autocomplete="off"
            @change="changeActiveTab('cardType'), getCardType()" />
          <label class="btn btn-outline-primary" for="btnradio0">Добавить тип карты</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
            @change="changeActiveTab('historyData'), searchAllData()" />
          <label class="btn btn-outline-primary" for="btnradio1">Вся история</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" data-bs-toggle="modal"
            data-bs-target="#a-login" @change="changeActiveTab('registerUser')" />
          <label class="btn btn-outline-primary" for="btnradio2">Зарегестрировать пользователя</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off"
            @change="changeActiveTab('usersnameData'), waitForAction()" />
          <label class="btn btn-outline-primary" for="btnradio3">Все ТЗ</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off"
            @change="changeActiveTab('usernameData'), searchUserData()" />
          <label class="btn btn-outline-primary" for="btnradio4">ТЗ пользователя</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio5" autocomplete="off"
            @change="changeActiveTab('cardsData'), searchUserData()" />
          <label class="btn btn-outline-primary" for="btnradio5">Список номеров карт по ТЗ</label>

          <!-- <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio3"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="btnradio3">Radio 3</label> -->
        </div>
      </div>
      <div class="s-admin__content-history s-admin__content-item">
        <div v-if="activeTab == 'historyData'" class="s-admin__history">
          <div class="s-admin__history-items">
            <template v-for="(item, i) in historyData">
              <ul :key="i" v-if="item.description != null" class="s-admin__history-item list-group list-group-horizontal">
                <li class="s-admin__history-item list-group-item">
                  № {{ historyData.length - i }}
                </li>
                <li class="s-admin__history-item list-group-item">
                  {{ item.description }}
                </li>
                <li class="s-admin__history-item list-group-item">
                  {{ toShortDate(item.created_at) }}
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
      <div class="s-admin__content-cards s-admin__content-item">
        <div v-if="activeTab == 'cardsData'" class="s-admin__cards">
          <div class="input-group flex-nowrap">
            <span class="input-group-text" id="addon-wrapping">id</span>
            <input type="text" v-model="cardsIdInput" class="form-control" placeholder="id технического задания"
              aria-label="id технического задания" aria-describedby="addon-wrapping">
            <div class="col-auto">
              <button type="submit" class="btn btn-primary" @click="getCardsNumber()">Отправить</button>
            </div>
          </div>
          <div class="s-admin__cards-items">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Номер карты</th>
                  <th scope="col">id чипа</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="card, i in cardsnumberData" :key="card">
                  <th>{{ i + 1 }}</th>
                  <th>{{ card.card_number }}</th>
                  <th>{{ card.mifare_id }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="s-admin__content-cardtype s-admin__content-item">
        <div v-show="activeTab == 'cardType'" class="s-admin__cardtype">
          <APopup id="a-cardtype">
            <SCardtype />
          </APopup>
          <APopup id="a-patch-cardtype">
            <MPatchCardtype />
          </APopup>
          <div class="s-admin__cardtype-tables">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Имя</th>
                  <th scope="col">серия</th>
                  <th scope="col">Блок №5</th>
                  <th scope="col">Блок №32</th>
                  <th scope="col">Блок №34</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="card, i in cardtypeData" :key="card">
                  <th>{{ i + 1 }}</th>
                  <th>{{ card.title }}</th>
                  <th>{{ card.series }}</th>
                  <th>{{ card.block_5 }}</th>
                  <th>{{ card.block_32 }}</th>
                  <th>{{ card.block_34 }}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="s-admin__cardtype-btns">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#a-cardtype">Добавить
              карту
            </button>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#a-patch-cardtype">
              Редактировать карту
            </button>
          </div>
          <div class="s-admin__cardtype-del">
            <form @submit.prevent="delCardType()">
              <div class="s-cardtype__form-input form-floating">
                <input type="text" v-model="input.namecardtype" class="s-cardtype__form-input form-control"
                  placeholder="Введите имя карты" />
                <label class="s-cardtype__form-label" for="floatingInput">Наименование карты</label>
              </div>
              <button class="s-cardtype__form-btn btn btn-danger py-2" type="submit">
                Удалить
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="s-admin__content-register s-admin__content-item">
        <div v-show="activeTab == 'registerUser'" class="s-admin-register">
          <APopup id="a-login">
            <SRegister />
          </APopup>
        </div>
      </div>
      <div class="s-admin__content-username s-admin__content-item">
        <div v-if="activeTab == 'usersnameData'" class="s-admin__username row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          <template v-for="i in usersnameData" :key="i">
            <MBlock class="s-admin__username-item" :item="i" @deleteItem="waitForAction" />
          </template>
        </div>
      </div>
      <div class="s-admin__content-user s-admin__content-item">
        <div v-if="activeTab == 'usernameData'"
          class="s-admin__user row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mt-10">
          <form @submit.prevent="searchUserData" class="s-admin__user-search d-flex w-100" role="search">
            <input class="s-admin__user-input form-control me-2" type="search" placeholder="Поиск" aria-label="Поиск"
              v-model="user" />
            <button class="s-admin__user-btn btn btn-outline-success" type="submit">Поиск</button>
          </form>
          <template v-for="i in usernameData" :key="i">
            <MBlock class="s-admin__user-item" :item="i" @deleteItem="waitForAction" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, watchEffect } from "vue";
import APopup from "@/components/a-popup.vue";
import SRegister from "@/components/s-register.vue";
import SCardtype from "@/components/s-cardtype.vue";
import MBlock from "@/components/m-block.vue";
import MPatchCardtype from "./m-patch-cardtype.vue";
const activeTab = ref("");
const historyData = ref([]);
const usersnameData = ref([]);
const user = ref("");
const usernameData = ref([]);
const cardtypeData = ref([]);
const cardsnumberData = ref([]);
const cardsIdInput = ref('')
const input = ref({
  namecardtype: '',
})

watchEffect(() => {
  waitForUpdate();
});

function waitForUpdate() {
  setTimeout(() => {
  }, 500);
}
function changeActiveTab(elem) {
  activeTab.value = elem;
}
async function searchAllData() {
  usersnameData.value = [];
  await axios
    .get(process.env.base_url + `/api/v1/all_users_history`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // // console.log(response.data);
      historyData.value = response.data;
      // errorData.value = false;
      // // console.log(sectionData.value);
      historyData.value = historyData.value.filter(
        (obj) => obj.description !== null
      );
    })
    .catch((response, error) => {
      historyData.value = [];
      // // console.log(error);
    });
}
async function searchAllUsername() {
  historyData.value = [];
  await axios
    .get(process.env.base_url + `/api/v1/all_tech_reqs`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      usersnameData.value = response.data;
    })
    .catch((response, error) => {
      usersnameData.value = [];
    });
}

async function getCardsNumber() {
  cardsnumberData.value =[];
    await axios
    .get(process.env.base_url + `/api/v1/get_card_number/${cardsIdInput.value}`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      cardsnumberData.value = response.data;
    })
    .catch((error) => {
      cardsnumberData.value = [];
    });
}

async function getCardType() {
  await axios.get(process.env.base_url + '/api/v1/all_cards_types', {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      cardtypeData.value = response.data;
    })
    .catch((response, error) => {
      cardtypeData.value = [];
      // console.log(error);
    });
}

async function delCardType() {
  await axios.delete(process.env.base_url + `/api/v1/delete_card_type/${input.value.namecardtype}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      input.value.namecardtype = ''
      getCardType()
    })
    .catch((response, error) => {
      // cardtypeData.value = [];
      // console.log(error);
    });
}

async function searchUserData() {
  usersnameData.value = [];
  let url = `/api/v1/all_tech_reqs`
  if (user.value != '') {
    url += `?username=${user.value}`
  }
  // console.log(url);
  await axios
    .get(
      process.env.base_url +
      url,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      usernameData.value = response.data;
    })
    .catch((response, error) => {
      usernameData.value = [];
      // console.log(error);
    });
}
function waitForAction(el) {
  // console.log(el);
  searchAllUsername();
  // emit("reworkData", el);
}
const toShortDate = (date) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString(undefined, options);
  const formattedTime = new Date(date).toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${formattedDate} ${formattedTime}`;
};
</script>

<style lang="scss" scoped>
.s-admin {
  &__content {
    &-item {
      margin-top: 10px;
    }
  }

  &__history {
    &-items {
      display: flex;
      flex-direction: column-reverse;
      gap: 10px;
    }

    &-item {
      width: 100%;
    }
  }

  &__cardtype {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-del {
      form {
        display: flex;
        width: 100%;

        .s-cardtype__form-input {
          width: 100%;
        }
      }
    }

    &-btns {
      display: flex;
      gap: 10px;
    }
  }
}
</style>
