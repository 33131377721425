<template>
  <SHeader />
  <main class="mt-10 mb-10 m-auto">
    <SPreview />
  </main>
  <SFooter />
</template>

<script>
import SPreview from "@/components/s-preview.vue";
import SHeader from "@/components/s-header.vue";
import SFooter from "@/components/s-footer.vue";

export default {
  components: {
    SPreview,
    SHeader,
    SFooter,
  },
};
</script>

<style scoped>
@page {
  size: A4;
  margin: 0;
}

main {
  display: inline-block;
  size: A4;
  width: 210mm;
  margin: 0 auto;
  font-family: "Times New Roman", Times, serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff; /* цвет фона страницы */
}
</style>
